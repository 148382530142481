.container {
    display: flex;
    flex-direction: column;
}

.input {
    height: 44px;
    font-weight: 500;
    color: #050038!important;
    box-shadow: none!important;
    outline: none;
    padding: 0.375rem 0.75rem!important;
    border: 1px solid rgba(119,121,145,.25);
    font-size: 14px;
    border-radius: 8px;

    box-sizing: border-box;
    width: 100%;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input:focus {
    border: 2px solid #050038;
}

.label {
    margin-bottom: 8px;
    color: #050038!important;
    font-weight: 500!important;
    line-height: normal;
    font-size: .9285714286rem;
}
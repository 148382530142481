.content {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    color: #050038;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    word-break: break-word;
    text-align: center;
}
.buttonWrapper {
    min-width: 36px;
    min-height: 36px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonWrapper:hover {
    opacity: 0.8;
}

.button {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    fill: black;
    stroke: black;
    stroke-width: 4px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.circle {
    border-radius: 50%;
    color: #fff;
    background-color: rgba(141,153,167,.25);
    display: flex;
    align-items: center;
    justify-content: center;
}

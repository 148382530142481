.modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
}

.modal__open {
    opacity: 1;
    z-index: 1000;
}

.contentWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
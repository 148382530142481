.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
    padding: 31px 37px 19px 40px;
}

/*.wrapper {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    align-items: flex-start;*/
/*    grid-column-gap: 12px;*/
/*    -webkit-column-gap: 12px;*/
/*    column-gap: 12px;*/
/*    justify-content: space-between;*/
/*}*/
@import url('vendor/fonts/fonts.css');

body {
  margin: 0;
  font-family: "Stem","Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color1: rgb(2, 45, 33);
  --primary: rgb(19, 119, 86);
  --secondary: rgb(241, 247, 246);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

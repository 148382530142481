.content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(5,0,56,.2);
    border-radius: 0.3rem;
    outline: 0;
    border-radius: 8px;
    border: none;
    width: 450px;
}